<template>
<div class="margin_rule">
  <div>视客联盟VODM电商MCN签约达人(矩阵作者）</div>
  <div class="title">履约金规则及合作公约：</div>
  <div class="tips">禁止事项：</div>
  <div class="tips">1.不得发布会在社会中引起争议(包括但不限于违反社会主流价值观、公共道德、风俗习惯，扰乱社会秩序，破坏社会稳定)的视频。</div>
  <div class="tips"> 2.不得发布会引起使艺人声誉受损的社会舆论且侵犯任何第三方的合法权益的视频。</div>
  <div class="tips">3.不得利用原始素材对我方艺人进行歪曲丑化等恶意剪辑、不得直接搬运我方艺人主账号的视频内容、不得擅自剪辑我方艺人的隐私素材等用以推广(包括但不限于家庭生活、 个人生活等),不得利用我方素材、我方商品链接私自进行牟利、推广非我方指定产品。</div>
  <div class="tips">4.不得将取得的相关推广素材用于抖音以外的其他渠道进行推广或其他用途。</div>
  <div class="tips">5.不得发布影响后台信用的违规视频。</div>
  <div class="tips">
    如因签约达人包括不限于进行协议第一条至第五条行为（禁止事项）并发布内容至平台，给MCN机构（视客联盟VODM）造成损失的，视为签约达人违约，MCN机构（视客联盟VODM）有权解除账号签约并要求签约达人承担赔偿责任，赔偿金额为签约期所涉及全部产品链接实际销售额的三倍或人民币10000元（壹万元整），两者以金额高者为签约达人赔偿金额。
  </div>
  <div class="tips">
    如因签约达人签约满3个月且【销售带货总佣金*(1-达人分成比例) 】未满200元，机构有权取消达人绑定，并扣除作者履约金（具体金额因授权IP而定）。
  </div>
  <div class="tips">
    如因签约达人主动申请机构解绑，且未满签约期，机构依据该签约达人账号带货佣金收入进行决策，有权拒绝，并有权要求签约达人在签约期因机构提供大主播、IP授权所获得的收益归还机构，并扣除作者履约金。
  </div>
  <div class="tips">
    如因签约达人在签约期内要求更换绑定的大主播、IP授权，机构依据签约达人申请及其创作内容，给予新的授权和创作内容素材。
  </div>
</div>
</template>

<script>
export default {
  name: "MarginRule"
}
</script>

<style scoped>

  .margin_rule{
    padding: 20px;
  }

  .title{
    text-align: center;
  }

  .tips{
    margin-top: 10px
  }
</style>
